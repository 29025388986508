import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-attachment-card-shared',
  templateUrl: './attachment-card-shared.component.html',
  styleUrls: ['./attachment-card-shared.component.scss'],
})
export class AttachmentCardSharedComponent {
  @Input() attachmentGroup: any;
  @Input() showDeleteAction: boolean;
  @Output() deleteAttachment: EventEmitter<any> = new EventEmitter();
  fileTypesMap: { [key: string]: { icon: string; displayName: string } } = {
    // Documents
    pdf: { icon: 'article', displayName: 'PDF' },
    doc: { icon: 'article', displayName: 'Word' },
    docx: { icon: 'article', displayName: 'Word' },
    xls: { icon: 'article', displayName: 'Excel' },
    xlsx: { icon: 'article', displayName: 'Excel' },
    rtf: { icon: 'article', displayName: 'RTF' },
    ppt: { icon: 'article', displayName: 'PowerPoint' },
    pptx: { icon: 'article', displayName: 'PowerPoint' },
    txt: { icon: 'article', displayName: 'Text' },
    accdb: { icon: 'article', displayName: 'Access Database' },
    accdr: { icon: 'article', displayName: 'Access Database Runtime' },
    accdt: { icon: 'article', displayName: 'Access Database Template' },
    accda: { icon: 'article', displayName: 'Access Add-In' },
    sb2: { icon: 'article', displayName: 'Scratch' },
    sb3: { icon: 'article', displayName: 'Scratch' },
    py: { icon: 'article', displayName: 'Python' },
    pyi: { icon: 'article', displayName: 'Python' },
    pyc: { icon: 'article', displayName: 'Python' },
    html: { icon: 'article', displayName: 'HTML' },
    xml: { icon: 'article', displayName: 'XML' },
    json: { icon: 'article', displayName: 'JSON' },
    // Images
    png: { icon: 'photo_size_select_actual', displayName: 'Image' },
    jpg: { icon: 'photo_size_select_actual', displayName: 'Image' },
    jpeg: { icon: 'photo_size_select_actual', displayName: 'Image' },
    // Videos
    mp4: { icon: 'not_started', displayName: 'Video' },
    // Audios
    mp3: { icon: 'music_video', displayName: 'Audio' },
    default: { icon: 'insert_drive_file', displayName: 'Unknown File' },
  };

  handleDelete() {
    this.deleteAttachment.emit();
  }

  getFileTypeInfo(extension: string): { icon: string; displayName: string } {
    return this.fileTypesMap[extension] || this.fileTypesMap['default'];
  }
}
