import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-announcement-shared-modal',
  templateUrl: './announcement-shared-modal.component.html',
  styleUrls: ['./announcement-shared-modal.component.scss'],
})
export class AnnouncementSharedModalComponent implements OnInit {
  selectedVideo: SafeResourceUrl;
  constructor(
    public dialogRef: MatDialogRef<AnnouncementSharedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.checkAndUpdateVideoUrl();
  }

  updateURL(url: string) {
    this.data.url = url;
    this.checkAndUpdateVideoUrl();
  }

  checkAndUpdateVideoUrl() {
    if (this.data && this.data.url) {
      const videoUrl = this.data.url.replace('/view', '/preview');
      this.selectedVideo =
        this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    }
  }

  close() {
    this.dialogRef.close();
    localStorage.setItem('showAnnouncement', '1');
  }
}
