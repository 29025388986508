import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-stream-assignment',
  templateUrl: './dashboard-stream-assignment.component.html',
  styleUrls: ['./dashboard-stream-assignment.component.scss'],
})
export class DashboardStreamAssignmentComponent {
  @Input() assignment: any;
  @Input() url: string;

  constructor(private router: Router) {}

  viewAssignment() {
    if (this.assignment.students && this.assignment.students.length > 0) {
      localStorage.setItem('selectedStudent', this.assignment.students[0]._id);
    }
    this.router.navigateByUrl(this.url);
  }
}
