import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-stream-material',
  templateUrl: './dashboard-stream-material.component.html',
  styleUrls: ['./dashboard-stream-material.component.scss'],
})
export class DashboardStreamMaterialComponent {
  @Input() material: any;
  @Input() url: string;

  constructor(private router: Router) {}

  viewMaterial() {
    if (this.material.students && this.material.students.length > 0) {
      localStorage.setItem('selectedStudent', this.material.students[0]._id);
    }
    this.router.navigateByUrl(this.url);
  }
}
