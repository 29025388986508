import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VacanciesSetupService {
  private readonly controller = 'admission/vacancies-setup';

  constructor(private generic: GenericService) {}

  getAllVacancies(limit, page) {
    const url = `limit=${limit}&page=${page}`;
    return this.generic.getDataByParams(this.controller, url);
  }

  getVacancyById(id) {
    return this.generic.getDataById(this.controller, id);
  }

  getVacanciesByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }

  createVacancy(body) {
    return this.generic.create(this.controller, body);
  }

  updateVacancy(id, body) {
    return this.generic.update(`${this.controller}/${id}`, body);
  }

  changeVacancyStatus(id, body) {
    return this.generic.patch(`${this.controller}/${id}`, body);
  }

  deleteVacancy(id) {
    return this.generic.delete(this.controller, id);
  }
}
