import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-lms-shared-view-reactions-modal',
  templateUrl: './lms-shared-view-reactions-modal.component.html',
  styleUrls: ['./lms-shared-view-reactions-modal.component.scss'],
})
export class LmsSharedViewReactionsModalComponent {
  reactions: any[];

  likeReactions = [];
  loveReactions = [];
  dislikeReactions = [];

  likeCount = 0;
  loveCount = 0;
  dislikeCount = 0;
  public emojiPath: string;

  constructor(
    public dialogRef: MatDialogRef<LmsSharedViewReactionsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reactions: any[];
      emojiPath: string;
    },
  ) {
    this.reactions = data.reactions;
    this.emojiPath = data.emojiPath;
  }

  ngOnInit() {
    this.likeReactions = this.reactions.filter(
      (reaction) => reaction.type === 'LIKE',
    );
    this.loveReactions = this.reactions.filter(
      (reaction) => reaction.type === 'LOVE',
    );
    this.dislikeReactions = this.reactions.filter(
      (reaction) => reaction.type === 'DISLIKE',
    );

    this.likeCount = this.likeReactions.length;
    this.loveCount = this.loveReactions.length;
    this.dislikeCount = this.dislikeReactions.length;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
