import { Component, OnInit } from '@angular/core';
import { ParentUserService } from 'src/app/core';

@Component({
  selector: 'app-child-selector-box',
  templateUrl: './child-selector-box.component.html',
  styleUrls: ['./child-selector-box.component.scss'],
})
export class ChildSelectorBoxComponent implements OnInit {
  children = [];
  studentId: any;
  constructor(private parentUserService: ParentUserService) {}

  ngOnInit() {
    this.getChildren();
    this.parentUserService.selectedChild$.subscribe((res: any) => {
      if (res && res.studentProfile) {
        this.studentId = res.studentProfile._id;
      } else {
        this.studentId = null;
      }
    });
  }

  getChildren() {
    this.parentUserService.getChildren().subscribe((res: any) => {
      this.children = res.data;
    });
  }

  getAllChildernData() {
    this.studentId = null;
    this.parentUserService.setSelectedChild(null);
  }

  getSelectedProfileData(child) {
    this.parentUserService.setSelectedChild(child);
  }
}
