import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ParentUserService {
  controller = 'parent';
  private classesInitialState = {
    data: [],
    studentId: '',
  };
  private classes$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.classesInitialState,
  );
  private classesDataLoading = false;

  // Children Selector
  private selectedChildSource = new BehaviorSubject<any>(null);
  public readonly selectedChild$ = this.selectedChildSource.asObservable();
  private childrenInitialState = {
    data: [],
  };
  private children$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.childrenInitialState,
  );
  private childrenDataLoading = false;

  constructor(
    private generic: GenericService,
    private authService: AuthService,
  ) {
    this.authService.getUser().subscribe((res) => {
      if (res == null) {
        this.classes$.next(this.classesInitialState);
        this.children$.next(this.childrenInitialState);
        this.selectedChildSource.next(null);
      }
    });
    const selectedStudent = localStorage.getItem('selectedStudent');
    this.children$.subscribe((res: any) => {
      if (res && res.data) {
        res.data.forEach((element) => {
          if (element.studentProfile._id === selectedStudent) {
            this.selectedChildSource.next(element);
          }
        });
      }
    });
  }

  setSelectedChild(child: any) {
    this.selectedChildSource.next(child);
    if (child) {
      localStorage.setItem('selectedStudent', child.studentProfile._id);
    } else {
      localStorage.removeItem('selectedStudent');
    }
  }

  getChildren(): Observable<any> {
    if (
      this.children$.getValue() &&
      this.children$.getValue() !== this.childrenInitialState
    ) {
      return this.children$.asObservable();
    } else if (!this.childrenDataLoading) {
      return this.setChildren();
    } else {
      return this.children$.asObservable();
    }
  }

  private setChildren(): Observable<any> {
    this.childrenDataLoading = true;
    return this.generic.getData(`${this.controller}/children`).pipe(
      tap((response) => {
        this.children$.next(response);
        this.childrenDataLoading = false;
      }),
    );
  }

  // TODO: Need to imporve this method
  filterChildByUserId(userId: string) {
    const children = this.children$.getValue().data;
    const filteredChild = children.find(
      (child) => child.studentProfile.users === userId,
    );
    if (filteredChild) {
      localStorage.setItem('selectedStudent', filteredChild.studentProfile._id);
      this.setSelectedChild(filteredChild);
      this.classesDataLoading = true;
      this.setClasses(filteredChild.studentProfile._id).subscribe();
    }
  }

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }

  update(body) {
    return this.generic.update(this.controller, body);
  }

  getbyParams(params) {
    const order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  dueDateExtension(body) {
    return this.generic.update(
      this.controller + '/dueDateExtension/request',
      body,
    );
  }
  getGmailCreditionals(stdID) {
    return this.generic.getData(`${this.controller}/credentials/${stdID}`);
  }

  getClasses(studentId): Observable<any> {
    if (
      this.classes$.getValue() &&
      this.classes$.getValue() !== this.classesInitialState &&
      studentId == this.classes$.getValue().studentId
    ) {
      return this.classes$.asObservable();
    } else if (!this.classesDataLoading) {
      return this.setClasses(studentId);
    } else {
      return this.classes$.asObservable();
    }
  }

  private setClasses(studentId): Observable<any> {
    this.classesDataLoading = true;
    return this.generic
      .getData(`${this.controller}/classes/student/${studentId}`)
      .pipe(
        tap((response) => {
          const newResponse = { ...response, studentId };
          this.classes$.next(newResponse);
          this.classesDataLoading = false;
        }),
      );
  }

  getClassById(classId) {
    return this.generic.getDataById(`${this.controller}/classes`, classId);
  }

  getStream(classId, params, order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `${this.controller}/classes/${classId}/stream`,
      params,
      order,
      sort,
    );
  }

  addReaction(classId, streamId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/stream/${streamId}/reaction`,
      body,
    );
  }

  getClassWork(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/classwork`,
    );
  }

  getByIdClassAssignment(classId, assignmentId, studentId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}?studentId=${studentId}`,
    );
  }

  getByIdMaterial(classId, materialId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/material`,
      materialId,
    );
  }

  uploadAnswerDocument(classId, assignmentId, questionId, body) {
    return this.generic.imageUpload(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/question/${questionId}/answer/upload-attachment`,
      body,
    );
  }

  deleteAnswerDocument(classId, assignmentId, questionId, url, studentId) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/question/${questionId}/answer/delete-attachment?fileUrl=${url}&studentId=${studentId}`,
    );
  }

  submitAssignment(classId, assignmentId, studentId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/submit?studentId=${studentId}`,
      body,
    );
  }

  getClassSettings(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/class-setting`,
    );
  }

  getClassGradebook(classId, assignTerm, studentId) {
    const params = `?assignTerm=${assignTerm}&studentId=${studentId}`;
    return this.generic.getData(
      `${this.controller}/classes/${classId}/gradebook${params}`,
    );
  }

  getGradebook(assignTermId, studentId) {
    const query = `?assignTerm=${assignTermId}&studentId=${studentId}`;
    return this.generic.getData(`${this.controller}/gradebook${query}`);
  }

  getAssignTerms(academicYearId) {
    return this.generic.getData(
      `${this.controller}/assignterms/${academicYearId}`,
    );
  }

  getNotifications() {
    return this.generic.getData(`${this.controller}/notifications`);
  }

  readNotification(notificationId) {
    return this.generic.patch(
      `${this.controller}/notifications/${notificationId}/read`,
      {},
    );
  }

  markAllNotificationsAsRead() {
    return this.generic.patch(`${this.controller}/notifications/read`, {});
  }
}
