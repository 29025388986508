import { Component, OnInit } from '@angular/core';
import { ParentUserService } from 'src/app/core';

@Component({
  selector: 'app-children-selector',
  templateUrl: './children-selector.component.html',
  styleUrls: ['./children-selector.component.scss'],
})
export class ChildrenSelectorComponent implements OnInit {
  studentId: any;
  children = [];
  constructor(private parentUserService: ParentUserService) {}

  ngOnInit() {
    this.parentUserService.selectedChild$.subscribe((res: any) => {
      if (res && res.studentProfile) {
        this.studentId = res.studentProfile._id;
      } else {
        this.studentId = null;
      }
    });

    this.parentUserService.getChildren().subscribe((res: any) => {
      this.children = res.data;
    });
  }

  getAllChildernData() {
    this.studentId = null;
    this.parentUserService.setSelectedChild(null);
  }

  getSelectedProfileData(child) {
    this.parentUserService.setSelectedChild(child);
  }
}
