import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgoOrDate',
  pure: false,
})
export class TimeAgoOrDatePipe implements PipeTransform {
  transform(date: string, format = 'MM/DD/YYYY'): string {
    if (!date) return '';
    const currentDate = moment();
    const givenDate = moment(date);
    const daysDiff = currentDate.diff(givenDate, 'days');
    if (daysDiff >= 0 && daysDiff < 7) {
      return givenDate.fromNow();
    } else {
      return givenDate.format(format);
    }
  }
}
