import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-result-confetti',
  templateUrl: './result-confetti.component.html',
  styleUrls: ['./result-confetti.component.scss'],
})
export class ResultConfettiComponent implements OnInit {
  canvasSettings = {
    target: 'canvas',
    size: 2,
    max: 50,
    props: ['square', 'triangle', 'line', 'circle'],
    colors: [
      [165, 104, 246],
      [230, 61, 135],
      [0, 199, 228],
      [253, 214, 126],
    ],
    rotate: true,
    clock: 80,
    animate: true,
  };

  private canvas: any;

  async ngOnInit() {
    const { default: ConfettiGenerator } = await import('confetti-js');
    this.initializeConfetti(ConfettiGenerator);
  }

  private initializeConfetti(ConfettiGenerator): void {
    this.canvas = new ConfettiGenerator(this.canvasSettings);
    this.canvas.render();
    this.updateConfettiSettings(ConfettiGenerator);
  }

  private updateConfettiSettings(ConfettiGenerator): void {
    const screenWidth = window.innerWidth;
    if (screenWidth < 576) {
      this.canvasSettings.max = 70;
      this.canvasSettings.size = 1;
    } else if (screenWidth < 1200) {
      this.canvasSettings.max = 50;
      this.canvasSettings.size = 2;
    } else {
      this.canvasSettings.max = 50;
      this.canvasSettings.size = 2;
    }

    this.canvas.clear();
    this.canvas = new ConfettiGenerator(this.canvasSettings);
    this.canvas.render();
  }
}
