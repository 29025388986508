import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatTabGroup } from '@angular/material';
import { Lightbox } from 'ngx-lightbox';
import { LmsSharedViewReactionsModalComponent } from 'src/app/lms-shared/lms-shared-view-reactions-modal/lms-shared-view-reactions-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-stream-post',
  templateUrl: './dashboard-stream-post.component.html',
  styleUrls: ['./dashboard-stream-post.component.scss'],
})
export class DashboardStreamPostComponent implements OnInit {
  @Input() stream: any;
  @Input() url: string;
  @Output() reactEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;

  showdiv: string;
  isButtonSelected = false;
  emojiList = ['LIKE', 'LOVE', 'DISLIKE'];
  reactionCount: any;
  userReactions = {};
  lastActiveReaction = '';
  showAllNavigation = false;
  userId = '';
  likeReactions = [];
  loveReactions = [];
  dislikeReactions = [];
  likeCount = 0;
  loveCount = 0;
  dislikeCount = 0;
  constructor(
    private lightbox: Lightbox,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.emojiList.forEach((emoji) => (this.userReactions[emoji] = false));
  }

  ngOnInit() {
    this.getActiveReaction();
    this.filterReactions();
  }

  open(index: number, album): void {
    const albumCopy = JSON.parse(JSON.stringify(album));
    albumCopy.forEach((item) => {
      if ('url' in item) {
        item.src = item.url;
        delete item.url;
      }
    });

    this.lightbox.open(albumCopy, index, {
      wrapAround: true,
      centerVertically: true,
      showImageNumberLabel: true,
      alwaysShowNavOnTouchDevices: true,
    });
  }

  viewStream() {
    if (this.stream.students && this.stream.students.length > 0) {
      localStorage.setItem('selectedStudent', this.stream.students[0]._id);
    }
    this.router.navigateByUrl(this.url);
  }

  onButtonClick() {
    this.isButtonSelected = true;

    const dialogRef = this.dialog.open(LmsSharedViewReactionsModalComponent, {
      data: {
        reactions: this.stream.reactions,
        emojiPath: '../../../../../assets/images/reactions',
      },
      panelClass: 'reaction-modal-zero-padding',

      width: '30vw',
      height: '60vh',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.isButtonSelected = false;
    });
  }

  openDrawer() {
    this.isButtonSelected = true;
    this.showAllNavigation = true;
    this.getActiveReaction();
    this.filterReactions();
  }
  closeDrawer() {
    this.isButtonSelected = false;
    this.showAllNavigation = false;
    this.setActiveTab(0);
  }
  setActiveTab(index: number): void {
    this.tabGroup.selectedIndex = index;
  }
  getActiveReaction() {
    this.userId = localStorage.getItem('id');

    this.stream.reactions.forEach((reaction) => {
      if (reaction._id === this.userId) {
        this.userReactions[reaction.type] = true;
        this.lastActiveReaction = reaction.type;
      }
    });
  }

  getReactionCount(emoji: string): number {
    return (
      (this.stream.reactionCounts && this.stream.reactionCounts[emoji]) || 0
    );
  }

  emojiPath(emoji) {
    return `../../../../../assets/images/reactions/${emoji}.svg`;
  }

  filterReactions() {
    this.likeReactions = this.stream.reactions.filter(
      (reaction) => reaction.type === 'LIKE',
    );
    this.loveReactions = this.stream.reactions.filter(
      (reaction) => reaction.type === 'LOVE',
    );
    this.dislikeReactions = this.stream.reactions.filter(
      (reaction) => reaction.type === 'DISLIKE',
    );

    this.likeCount = this.likeReactions.length;
    this.loveCount = this.loveReactions.length;
    this.dislikeCount = this.dislikeReactions.length;
  }

  onClickedOutside(e) {
    this.showdiv = '';
  }

  react(classId, streamId, val) {
    let reactionRemoved = false;

    if (this.lastActiveReaction === val && this.userReactions[val]) {
      reactionRemoved = true;
      this.userReactions[val] = false;
      this.lastActiveReaction = null;
    } else {
      if (this.lastActiveReaction && this.lastActiveReaction !== val) {
        this.userReactions[this.lastActiveReaction] = false;
      }
      this.userReactions[val] = true;
      this.lastActiveReaction = val;
    }

    this.userReactions = { ...this.userReactions };

    const reaction = {
      classId,
      streamId,
      reaction: val,
      removed: reactionRemoved,
    };

    this.filterReactions();

    this.reactEvent.emit(reaction);
  }

  trackByFunction(index, item) {
    return item._id;
  }
}
