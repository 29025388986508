import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatTabGroup } from '@angular/material';
import { Lightbox } from 'ngx-lightbox';
import { UserType } from '../../enums-constant/user-type.enum';
import { ViewReactionModalComponent } from '../view-reaction-modal/view-reaction-modal.component';

@Component({
  selector: 'app-user-post',
  templateUrl: './user-post.component.html',
  styleUrls: ['./user-post.component.scss'],
})
export class UserPostComponent implements OnInit {
  @Input() post: any;
  @Output() reactEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;

  UserType = UserType;
  isAdmin: boolean;
  notSupportingMeg = 'Your browser does not support the video tag.';
  emojiList = ['LIKE', 'LOVE', 'DISLIKE'];
  userReactions = {};
  lastActiveReaction = '';
  userId = '';
  isButtonSelected = false;
  showAllNavigation = false;
  likeReactions = [];
  loveReactions = [];
  dislikeReactions = [];
  likeCount = 0;
  loveCount = 0;
  dislikeCount = 0;
  showdiv: string;
  userType = '';

  constructor(private lightbox: Lightbox, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getActiveReaction();
    this.filterReactions();
  }

  onButtonClick() {
    this.isButtonSelected = true;
    const dialogRef = this.dialog.open(ViewReactionModalComponent, {
      data: {
        reactions: this.post.reactions,
        emojiPath: '../../../../../assets/images/reactions',
      },
      panelClass: 'reaction-modal-zero-padding',
      width: '30vw',
      height: '60vh',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.isButtonSelected = false;
    });
  }
  filterReactions() {
    this.likeReactions = this.post.reactions.filter(
      (reaction) => reaction.type === 'LIKE',
    );
    this.loveReactions = this.post.reactions.filter(
      (reaction) => reaction.type === 'LOVE',
    );
    this.dislikeReactions = this.post.reactions.filter(
      (reaction) => reaction.type === 'DISLIKE',
    );

    this.likeCount = this.likeReactions.length;
    this.loveCount = this.loveReactions.length;
    this.dislikeCount = this.dislikeReactions.length;
  }
  getActiveReaction() {
    this.userType = localStorage.getItem('user_type');
    this.userId = localStorage.getItem('id');
    if (this.userType === UserType.Admin || this.userType === UserType.Staff) {
      this.isAdmin = true;
    }
    this.post.reactions.forEach((reaction) => {
      if (reaction.userId._id === this.userId) {
        this.userReactions[reaction.type] = true;
        this.lastActiveReaction = reaction.type;
      }
    });
  }
  openDrawer() {
    this.isButtonSelected = true;
    this.showAllNavigation = true;
    this.getActiveReaction();
    this.filterReactions();
  }

  closeDrawer() {
    this.isButtonSelected = false;
    this.showAllNavigation = false;
    this.setActiveTab(0);
  }

  setActiveTab(index: number): void {
    this.tabGroup.selectedIndex = index;
  }
  onClickedOutside(e) {
    this.showdiv = '';
  }

  getBranchGradeNames(branchName: string, grades: any[]): string {
    if (!branchName) return 'All Branches';
    if (!grades || grades.length === 0) {
      return branchName;
    }
    return `${branchName} - ${grades.map((grade) => grade.name).join(', ')}`;
  }

  open(index: number, album): void {
    if (album.length) {
      for (let i = 0; i < album.length; i++) {
        album[i] = Object.assign({}, album[i], {
          src: album[i].url,
        });
      }
    }
    this.lightbox.open(album, index, {
      wrapAround: true,
      centerVertically: true,
      showImageNumberLabel: true,
      alwaysShowNavOnTouchDevices: true,
    });
  }

  emojiPath(emoji) {
    return `../../../../../assets/images/reactions/${emoji}.svg`;
  }

  react(postId, val) {
    let reactionRemoved = false;

    if (this.lastActiveReaction === val && this.userReactions[val]) {
      reactionRemoved = true;
      this.userReactions[val] = false;
      this.lastActiveReaction = null;
    } else {
      if (this.lastActiveReaction && this.lastActiveReaction !== val) {
        this.userReactions[this.lastActiveReaction] = false;
      }
      this.userReactions[val] = true;
      this.lastActiveReaction = val;
    }

    this.userReactions = { ...this.userReactions };

    const reaction = {
      postId,
      reaction: val,
      removed: reactionRemoved,
    };

    this.reactEvent.emit(reaction);
  }

  trackByFunction(index, item) {
    return item._id;
  }
  getReactionCount(emoji: string): number {
    return (this.post.reactionCounts && this.post.reactionCounts[emoji]) || 0;
  }

  close(): void {
    this.lightbox.close();
  }
}
