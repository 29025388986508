import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SwitcherInteractorService {
  messageSource = new Subject<string>();
  menuSource = new Subject<any>();
  private _dataWidth: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  private navigation$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(private authService: AuthService) {
    this.authService.getUser().subscribe((res: any) => {
      if (res == null) {
        this.navigation$.next(null);
      }
    });
    const navigation = JSON.parse(localStorage.getItem('navigation'));
    this.navigation$.next(navigation);
  }

  setNavigation(navigation) {
    this.navigation$.next(navigation);
  }

  getNavigation() {
    return this.navigation$.asObservable();
  }

  getMessage(): Observable<string> {
    return this.messageSource.asObservable();
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  public setWidth(data: any) {
    this._dataWidth.next(data);
  }

  public getWidth(): Observable<any> {
    return this._dataWidth.asObservable();
  }

  public setMenu(data: any) {
    console.log(data);
    this.messageSource.next(data);
  }

  public getMenu(): Observable<any> {
    return this.menuSource.asObservable();
  }
}
