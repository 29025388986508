import { RouterModule, Routes } from '@angular/router';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { AuthGuardService } from './core/guard/auth-guard-service.service';
import { Error404Component } from './core/error-pages/error404/error404.component';
import { NgModule } from '@angular/core';
import { UserAuthGuard } from './core/guard/user-auth.guard';

const routes: Routes = [
  {
    path: 'invitation/:id',
    component: AcceptInvitationComponent,
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuardService],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'parent',
    loadChildren: () =>
      import('./parent-user/parent-user.module').then(
        (m) => m.ParentUserModule,
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'student',
    loadChildren: () =>
      import('./student-user/student-user.module').then(
        (m) => m.StudentUserModule,
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'teacher',
    loadChildren: () =>
      import('./teacher-user/teacher-user.module').then(
        (m) => m.TeacherUserModule,
      ),
    canActivate: [UserAuthGuard],
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./registration/registration.module').then(
        (m) => m.RegistrationModule,
      ),
  },
  {
    path: 'not-found',
    component: Error404Component,
    data: {
      url: '/',
    },
  },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
